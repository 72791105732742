<template>
  <div class="home">
    <CameraSimulator/>
  </div>
</template>

<script>
// @ is an alias to /src
import CameraSimulator from '@/components/CameraSimulator.vue'

export default {
  name: 'HomeView',
  components: {
    CameraSimulator
  }
}
</script>
