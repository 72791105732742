<template lang="">
    <a @click='changeUsername()' :class="buttonClass" href="#carouselExampleControls" role="button" :data-slide="order">
      <span :class="iconClass" aria-hidden="true" ></span>
      <span class="sr-only"  ><slot></slot></span>
    </a>
    <!-- <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  <span class="sr-only"></span>
</a> -->
</template>
<script>
//import Vue from 'vue'
// const bus = new Vue(); @click.prevent="handleClick"
export default {
    name: "CarouselControl",
     props: ['order','bus'],
  data() {
    return {
      buttonClass: `carousel-control-${this.order}`
    }
  },
  computed: {
    iconClass() {
      return `${this.buttonClass}-icon`;
    }
  },
  methods: {
       changeUsername() {
    if (this.order === 'next') {
     this.$emit('goNext')
      } else if (this.order === 'prev') {
        this.$emit('goPrev');
      }
   },
    handleClick() {
        console.log('a click '+this.order);
      if (this.order === 'next') {
        this.bus.$emit('goNext');
      } else if (this.order === 'prev') {
        this.bus.$emit('goPrev');
      }
    }
  }
}
</script>
<style lang="">

</style>