// import Vue from 'vue'
import HomeView from '../views/HomeView.vue'
import PhoneView from '../views/PhoneView.vue'
import VueRouter from 'vue-router'
import SmartphoneView from '../views/SmartphoneView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/phone',
    name: 'phone',
    component: PhoneView
  },
  {
    path: '/smartphone',
    name: 'smartphone',
    component: SmartphoneView
  },
  {
    path: "*",
    component: () => import("../views/NotFound.vue")
    }
]

const router = new VueRouter({
  routes
})

export default router
