<template>
  <div  id="PhoneSimulator">
        <div class=" container-fluid" style="padding-top: 20px ; padding-bottom: 20px">
            <main>
                <div class="row" style="padding-top: 10px;padding-bottom: 10px;">
                    <div class="col-md-4 col-12">
                        <div class="card-deck">
                            <div class="card">
                                <img style="padding-top: 5px;" class="card-img-top" src="../assets/iphone14.jpeg" 
                                    alt="Card image cap">
                                <div class="card-body" style="text-align: center;">
                                    
                                    <h5 class="card-title">Iphone 14 pro max</h5>
                                    <p class="card-text">Customer ident code: 868276106892754</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8 col-12">
                    
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                              <div class="card-header" id="headingOne">
                                <h5 class="mb-0">
                                  <button style="width: 100%;" class="btn btn-link" type="button" data-toggle="collapse" 
                                  data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span style="border: 10px solid rgb(53, 223, 138); color: white; background-color: rgb(53, 223, 138);" 
                                    >GET</span>  <span style="padding-left: 10px; padding-right: 10px;">
                                        api/capturePic/customerCamerasIdent/:customerCamerasIdent </span>
                                        mobile comand api
                                  </button>
                                  </h5>
                              </div>
                
                              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>https://api-dev.vtcamera.de/api/capturePic/customerCamerasIdent/:customerCamerasIdent</p>
                                     <h4>To be done..</h4>
                             <!--        <p>The camera calls this GET api with a valid customer camera ident code and receives a response from the backend.
                                        The backend response is based on the random true and false values for the command sendPics along with a jobID which is generated as a universally
                                        unique identifier (UUID). For more information please refer to <a href="https://ventrade.atlassian.net/wiki/spaces/MAPP2/pages/2146959374/Scope+of+project+-+Phase+1">section</a>.
                                    </p>
                                    <button @click="checkSendPics(868276106892753)" type="button" class="btn btn-light">Try it out</button>
                                    <hr/>
                                    <p>API response</p>
                                    <pre style="background-color: rgb(155, 46, 46); color: white;">{{checkSendPicsRes}}</pre>
                                 -->
                                  </div>
                              </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                  <h5 class="mb-0">
                                    <button style="width: 100%;" class="btn btn-link" type="button" data-toggle="collapse" 
                                    data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                      <span style="border: 10px solid orange; color: white; background-color: orange;" 
                                      >POST</span>  <span style="padding-left: 10px; padding-right: 10px;">
                                          api/capturePic/customerCamerasIdent/:customerCamerasIdent </span>
                                           mobile comand api
                                    </button>
                                    </h5>
                                </div>
                  
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                  <div class="card-body">
                                    <p>https://api-dev.vtcamera.de/api/capturePic/customerCamerasIdent/:customerCamerasIdent
                                    </p>
                                    <h4>To be done..</h4>
                                 <!--    <p>The camera calls this POST api with a valid customer camera ident code and request body including jobId and status. 
                                        The status has two values 1 for acknowledge and 0 for unacknowledge. The camera calls this api to send an acknowledgment message to the backend server.
                                        For more information please refer to <a href="https://ventrade.atlassian.net/wiki/spaces/MAPP2/pages/2146959374/Scope+of+project+-+Phase+1">section</a>.
                                    </p>
                                <form class="form" role="form">
                                    <div class="form-row">
                                        <div class="form-group col-md-7">
                                            <label for="inputEmail">JobId</label>
                                            <input type="text" class="form-control" 
                                            v-model="reqBodyAck.jobId"
                                            placeholder="type should be uuid"
                                            >
                                        </div>
                                    </div>
                                 <div class="form-row">
                                    <div class="form-group col-md-7">
                                        <label for="inputPassword">Status</label>
                                        <input type="text" class="form-control" 
                                        v-model="reqBodyAck.status"
                                        placeholder="0 (not acknowledge) or 1 (acknowledged)"
                                        >
                                    </div>
                                 </div>
                                      
                                    
                                    </form>
                                
                                      <button @click="acknowledgeAPI(868276106892753,reqBodyAck)" type="button" class="btn btn-light">Try it out</button>
                                      <hr/>
                                      <p>Request body</p>
                                      <pre style="background-color: rgb(155, 46, 46); color: white;">{{reqBodyAck}}</pre>
                                      <hr/>
                                      <p>API response</p>
                                      <pre style="background-color:  rgb(155, 46, 46);  color: white;">{{acknowledgeAPIRes}}</pre>
                                    -->
                                    </div>
                                </div>
                              </div>
                          </div>
                    </div>

                </div>

            </main>
        </div>


  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PhoneSimulator',
  props: {
    msg: String
  },
   data: function ()  {
    return {
      titleName: "test",
      reqBodyAck: {
        status: "",
        jobId: "",
      },
      checkSendPicsRes: "{}",
      acknowledgeAPIRes: "{}",
      jsonstr:
        '{"id":1,"name":"A green door","price":12.50,"tags":["home","green"]}',
      appVersion: "",
      testBool: true,
      runningEnv: "",
      //customer images
      errors: [],
      statusMessage: "Click apply to save app settings.",
      isLoading: false,
      button: {
        text: "Apply",
      },
      appConfig: {
        active: "false",
        imageSMSActive: "false",
        reportSMSActive: "false",
        imageCallActive: "false",
        reportCallActive: "false",
        mcMailSMSActive: "false",
        contactList: [],
      },
      logs: JSON,
      showLogs: false,
      databaseId: "ImagesReports-PROD",
      minDays: 90,
      currentEnv: "",
      textAreaCustomerPicsLogs: String,
      removeErrorsMsg: false,
    };
  },
  created() {
    console.log("çreated");
    // this.loadAppConfigs();
    // this.getEnv();
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  methods: {
      
    async checkSendPics(customerIC) {
      console.log("CCIC: " + customerIC);
      let customerIdentCode = customerIC;
      await axios
        .get(`api/checkSendPics/customerCamerasIdent/${customerIdentCode}`)
        .then((res) => {
          this.checkSendPicsRes = res.data;
          console.log("checkSendPics response " + JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    async acknowledgeAPI(customerCamerasIdent, reqBody) {
      console.log(customerCamerasIdent);
      console.log(reqBody);
      await axios
        .post(
          `api/checkSendPics/customerCamerasIdent/${customerCamerasIdent}`,
          reqBody
        )
        .then((res) => {
          console.log(res.data);
          this.acknowledgeAPIRes = {
            message: res.data,
          };
          console.log("Acknw " + JSON.stringify(this.acknowledgeAPIRes));
        })
        .catch((err) => {
          console.log(err.message);
        });
    }

  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
    background-color: rgb(155, 46, 46);
    color: white;
}
.btn {
  text-transform: unset !important;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #f4f6f8;
}
.card-img-top {
  width: 400px;
  height: 15vw;
  object-fit: contain;
}
</style>
