<template>
  <PhoneSimulator></PhoneSimulator>
</template>

<script>
// @ is an alias to /src
import PhoneSimulator from '@/components/PhoneSimulator.vue'

export default {
  name: 'PhoneView',
  components: {
    PhoneSimulator
  }
}
</script>