<template lang="">
   <transition name="slide">
      <div style="padding-left:8vh" class="carousel-item" :class="directionClass" v-show="active">
        <img class="d-block w-100"   id="myDiv"  :src="link"  :alt="imageName" ref="myDiv">
      </div>
    </transition>
</template>
<script>
export default {
     name: "CarouselItem",
     props: ['link', 'imageName', 'active', 'directionClass'],
       data: function () {
    return {
      testLink: 'https://picsum.photos/id/237/200/300',
   
    };
  },
     methods:{
           getImgUrl(pic) {
   return require('../assets/' + pic)
    }
     }
}
</script>
<style scoped>

.w-100 {
  width: 80% !important;
  height: 40%;
}
</style>