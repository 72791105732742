<template>
  <div id="app">
  <NavComponent/>
    <router-view/>
  <FooterComponent/>
      
  </div>
</template>

<script>
// @ is an alias to /src
import NavComponent from '@/components/NavComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
//import CarouselComponent from '@/components/CarouselComponent.vue'
export default {
  name: 'app',
  components: {
    NavComponent,
    FooterComponent,
    //CarouselComponent
  }
}
</script>
<style>
#app {
  font-family: Helvetica, Arial, sans-serif , Avenir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
