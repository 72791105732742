<template>
  <div id="CameraSimulator">
    <div class="container-fluid">
      <main>
        <div class="row" style="padding-top: 5vh; padding-bottom: 10px">
          <div class="col-md-6">
            <div class="card">
              <form class="form-inline">
                <input
                  type="text"
                  class="form-control mb-2 mr-sm-"
                  v-model="imei"
                  placeholder="Enter the IMEI"
                />
                <button
                  type="submit"
                  class="btn button mb-2"
                  @click="getLastImages($event)"
                >
                  Load pictures
                </button>
              </form>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="padding-top: 5vh; padding-bottom: 10px"
          v-show="cameraList.length > 0"
        >
          <!--    <div class="col-md-6 col-12">
            <div class="accordion" id="accordionExample" >
              <div class="card" >
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      style="width: 100%"
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Upload picture
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <p>Upload picture for animal detection.</p>
                    <div class="Row">
                      <input
                        class="Column"
                        type="file"
                        @change="uploadFile"
                        multiple
                        ref="file"
                      />
                      <button
                        class="Column btn btn-success"
                        type="button"
                        @click="submitFile"
                      >
                        Upload
                      </button>
                    </div>
                    <div
                      v-if="
                        imageMetadata && Object.keys(imageMetadata).length != 0
                      "
                    >
                      <p>Metadata</p>
                      <textarea v-model="imageMetadata"
                        >
                      </textarea>
                      <br />
                      <button class="btn btn-dark" @click="downloadJson">
                        Download
                      </button>
                      <button class="btn btn-info" @click="copyToClipboard">
                        Copy to clipboard
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-md-6 col-12">
            <div class="card-deck">
              <div class="card" style="height: 70%">
                <div class="card-body" style="text-align: center">
                  <CarouselComponent
                    :cameraList="cameraList"
                    @changeActiveCamera="changeCamera($event)"
                  >
                  </CarouselComponent>
                  <p class="card-text">
                    image name:
                    {{ selectedCamera.imageName }}
                  </p>

                  <hr />

                  <button
                    :disabled="isLoading"
                    class="Column btn button"
                    type="button"
                    @click="zeissifyAPI"
                  >
                    <span
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    ZEISSIFY
                  </button>
                  <hr />
                </div>
                <div
                  class="card-body"
                  style="text-align: center"
                  v-if="Object.keys(imageMetadata).length == 0"
                >
                  <p style="color: rgb(155, 46, 46)">
                    Please ZEISSIFY the picture to see AI Results
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="accordion" id="imageMetadataAccordion">
              <div
                class="card"
                v-if="imageObject && Object.keys(imageObject).length != 0"
              >
                <div class="card-header" id="imageMetaDataHeading">
                  <h5 class="mb-0">
                    <button
                      style="width: 100%"
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#imageCollapse"
                      aria-expanded="true"
                      aria-controls="imageCollapse"
                    >
                      <p>Image Meta data</p>
                    </button>
                  </h5>
                </div>
                <div
                  id="imageCollapse"
                  class="collapse show"
                  aria-labelledby="imageMetaDataHeading"
                  data-parent="#imageMetadataAccordion"
                >
                  <div class="card-body">
                    <textarea v-model="imageObject"> </textarea>
                    <br />
                    <button class="btn btn-dark" @click="downloadImageJson">
                      Download
                    </button>
                    <button
                      class="btn btn button"
                      @click="copyImageToClipboard"
                    >
                      Copy to clipboard
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion" id="accordionExample">
              <div
                class="card"
                v-if="imageMetadata && Object.keys(imageMetadata).length != 0"
              >
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      style="width: 100%"
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>AI Results CosmosDB</p>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <textarea v-model="imageMetadata"> </textarea>
                    <br />
                    <button class="btn btn-dark" @click="downloadAIResultsJson">
                      Download
                    </button>
                    <button
                      class="btn btn button"
                      @click="copyAIResultsToClipboard"
                    >
                      Copy to clipboard
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card" style="width:600px, height:400px">
              <div>
                <!-- <p>Canvas:</p> -->
                <div>
                  <canvas
                    class="canvas d-block w-100"
                    style="border: 1px solid #000"
                    id="myCanvas"
                    :width="getWidth"
                    :height="getHeight"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CarouselComponent from "@/components/CarouselComponent.vue";

export default {
  name: "CameraSimulator",
  components: { CarouselComponent },
  props: {
    msg: String,
  },
  computed: {
    getWidth() {
      if (this.selectedCamera.imageWidth) {
        return this.selectedCamera.imageWidth + "px";
      } else {
        return "1280px";
      }
    },
    getHeight() {
      if (this.selectedCamera.imageHeight) {
        return this.selectedCamera.imageHeight + "px";
      } else {
        return "960px";
      }
    },
  },
  data: function () {
    return {
      stageSize: {
        width: 2560,
        height: 1920,
      },
      isLoading: false,
      image: null,
      mediaLink:
        "https://pic2aiassistant.ventradecluster.de/media/APIimageupload/files/images/20/24/59/864004047202459/20221220143020_864004047202459-23217-4g-26_SCEU0059.jpg",
      testJson:
        '{"id":1,"name":"A green door","price":12.50,"tags":["home","green"]}',
      imageMetadata: {},
      imageObject: {},
      appVersion: "",
      runningEnv: "",
      removeErrorsMsg: false,
      selectedCamera: {
        id: Number,
        link: String,
        imageName: String,
        imageWidth: 0,
        imageHeight: 0,
        isActive: Boolean,
        cameraidentcode: Number,
        body: {},
      },
      cameraList: [],
      imei: "",
      noPicFoundError:"hi"
    };
  },

  created() {
    // this.createImage();
    //   this.drawRectangle(1)
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  methods: {
    uploadFile() {
      this.imageMetadata = {};
      this.Images = this.$refs.file.files[0];
    },
    showPicture(metaData) {
      if (metaData && metaData["link"] && metaData["imagename"]) {
        (this.selectedCamera = {
          id: metaData["imagename"],
          link: metaData["link"],
          imageName: metaData["imagename"],
          imageWidth: metaData["imageWidth"],
          imageHeight: metaData["imageHeight"],
          isActive: true,
          cameraidentcode: metaData["imei"],
        }),
          this.cameraList.push({
            id: metaData["imagename"],
            link: metaData["link"],
            isActive: true,
            imageName: metaData["imagename"],
            imageWidth: metaData["imageWidth"],
            imageHeight: metaData["imageHeight"],
          });
      }
    },
    seeGallery() {
      console.log(JSON.stringify(this.selectedCamera));
    },
    changeCamera(camera) {
      this.selectedCamera = camera;
      this.imageObject = JSON.stringify(this.selectedCamera.body, null, 2);
      // console.log(JSON.stringify(this.selectedCamera));
    },
    async submitFile() {
      const formData = new FormData();
      formData.append("image", this.Images);
      const headers = { "Content-Type": "multipart/form-data" };
      await axios
        .post(
          "https://pic2aiassistant.ventradecluster.de/api/upload-image/save-in-filesystem",
          formData,
          {
            headers,
          }
        )
        .then((res) => {
          res.data.files;
          res.status;
          // console.log(res.data);
          if (res.status == 200) {
            console.log(res.data);
            this.imageMetadata = JSON.stringify(res.data, null, 2);
            this.showPicture(res.data);
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.imageMetadata = {
            error: error.message,
          };
        });
    },
    async getLastImages() {
      this.cameraList = [];
      let url = `https://pic2aiassistant.dev.ventradecluster.de/api/images/imei/${this.imei}`;
      await axios
        .get(url)
        .then((res) => {
          res.status; // HTTP status
          console.log(res.status);
          // console.log(res.data);
          if (res.status == 200) {
            // console.log(res.data);
            let imageList = res.data;
            if (imageList && imageList.length > 0) {
              this.createPictureList(imageList);
            }

          }
        })
        .catch((error) => {
          console.log(error.message);
          this.imageMetadata = {
            error: error.message,
          };
        });
    },
    createPictureList(imageList) {
      for (const i in imageList) {
        let image = imageList[i];
        if (image && image["link"] && image["imagename"]) {
          let computed =
            image["exif"] && image["exif"]["COMPUTED"]
              ? image["exif"]["COMPUTED"]
              : null;
          let height =
            computed && computed["Height"] ? computed["Height"] : 960;
          let width = computed && computed["Width"] ? computed["Width"] : 1280;
          this.cameraList.push({
            id: image["imagename"],
            link: image["link"],
            isActive: false,
            imageName: image["imagename"],
            imageWidth: width,
            imageHeight: height,
            body: image,
          });
          if (i == 0) {
            let cam = {
              id: image["imagename"],
              link: image["link"],
              isActive: true,
              imageName: image["imagename"],
              imageWidth: width,
              imageHeight: height,
              body: image,
            };
            this.selectedCamera = cam;
          }
        }
      }
    },
    clearCanvas() {
      var canvas = document.getElementById("myCanvas");
      var context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    },
    async loginAPI() {},
    async zeissifyAPI() {
      this.isLoading = true;
      this.imageMetadata = {};
      this.clearCanvas();
      let body = this.selectedCamera.body;
      // console.log(JSON.stringify(body));
      let url = `https://pic2aiassistant.dev.ventradecluster.de/api/zeissify/recognition-colorization`;
      await axios
        .post(url, body)
        .then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          console.log(res.status);
          // console.log(res.data);
          if (res.status == 200) {
            // console.log(res.data);
            this.imageMetadata = JSON.stringify(res.data, null, 2);
            this.applyDetections(res.data);
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.imageMetadata = {
            error: error.message,
          };
        });
    },
    applyDetections(ai_Results) {
      let aiDetection = ai_Results["aiDetection"]
      let detections = aiDetection["detections"]
      let aiColorization = ai_Results["aiColorization"] ? ai_Results["aiColorization"] : null;
      console.log(`Detections ${JSON.stringify(detections)}`);
      var canvas = document.getElementById("myCanvas");
      var context = canvas.getContext("2d");
      let img = new Image();
      img.src = aiColorization && aiColorization["imageLinkColorized"] ? aiColorization["imageLinkColorized"] : this.selectedCamera.link;
      img.id = "detection";
      img.width = this.selectedCamera.imageWidth;
      img.height = this.selectedCamera.imageHeight;
      console.log("this.ctx:", context);
      img.onload = () => {
        // console.log(img);
        // let cImg = document.getElementById("detection");
        // console.log(cImg);
        context.drawImage(img, 0, 0, img.width, img.height);
        console.log("canvas width " + canvas.width);
        context.beginPath();
        let width = this.selectedCamera.imageWidth;
        let height = this.selectedCamera.imageHeight;
          if(detections!=null){
          console.log('yesy');

           for (const x in detections) {
          if (detections[x].conf && detections[x].conf > 0.4) {
            //let conf =detections[x].conf;
            let bbox = detections[x].bbox;
            let dx = width * bbox[0];
            let dy = height * bbox[1];
            let dwidth = width * bbox[2];
            let dheight = height * bbox[3];
            console.log(detections[x] );
            let category = detections[x].category;
             let species = "";
                        // if(category=="vehicle") break;
            if(category=="animal")
          {
          species =
              detections[x].species && detections[x].species[0].processedLabel
                ? detections[x].species[0].processedLabel
                : detections[x].category;
          }
          else {
            species =detections[x].category
          }
                   context.strokeStyle = 'blue';
                    context.lineWidth = 7;
                    context.strokeRect(dx, dy, dwidth, dheight);
               context.font = "bold 30px Arial";
                     context.fillStyle = 'blue';
                //   const textHeight = context.measureText(species).height;
                    const textWidth = context.measureText(species).width;
                    context.fillRect(dx, dy, textWidth + 8, 20);
                    context.fillStyle = 'white';
                    context.fillText(species, dx + 4, dy + 16);   

            // context.lineWidth = 7;
            // context.strokeStyle = "blue";
            // context.fillStyle = "white";
            // context.font = "30px Arial";
            // context.fillText(species, dx - 20, dy - 20);
            //  context.rect(dx, dy, dwidth, dheight);
            // context.stroke();
          }
        }
          }
      };
      this.isLoading = false;
    },
    drawRectangle(convertedDetections) {
      console.log("drawing pics...");
      var canvas = document.getElementById("myCanvas");
      var context = canvas.getContext("2d");
      let img = new Image();
      img.src = this.selectedCamera.link;
      img.id = "detection";
      img.width = this.selectedCamera.imageWidth;
      img.height = this.selectedCamera.imageHeight;
      console.log("this.ctx:", context);
      img.onload = () => {
        console.log(img);
        let cImg = document.getElementById("detection");
        console.log(cImg);
        context.drawImage(img, 0, 0, img.width, img.height);
        console.log("canvas width " + canvas.width);
        context.beginPath();
        for (const x in convertedDetections) {
          let bbox = convertedDetections[x].bbox;
          console.log(bbox);
          context.rect(bbox[0], bbox[1], bbox[2], bbox[3]);
        }
        context.lineWidth = 7;
        context.strokeStyle = "red";
        context.stroke();
      };
    },

    getImgUrl(pic) {
      return require("../assets/" + pic);
    },
    copyImageToClipboard() {
      let text = JSON.stringify(this.selectedCamera.body, null, 2);
      console.log(text);
      var input = document.createElement("textarea");
      input.setAttribute("value", text);
      input.value = text;
      document.body.appendChild(input);
      try {
        input.select();
        input.click();
        input.focus();
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Testing code was copied " + successful + " " + msg);
      } catch (err) {
        console.log("Oops, unable to copy");
      }
      document.body.removeChild(input);
    },
    downloadImageJson() {
      let imageName = this.selectedCamera.body["imagename"].split(".")[0];
      let text = JSON.stringify(this.selectedCamera.body, null, 2);
      let filename = `${imageName}.json`;
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    copyAIResultsToClipboard() {
      let text = JSON.stringify(this.imageMetadata, null, 2);
      console.log(text);
      var input = document.createElement("textarea");
      input.setAttribute("value", text);
      input.value = text;
      document.body.appendChild(input);
      try {
        input.select();
        input.click();
        input.focus();
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Testing code was copied " + successful + " " + msg);
      } catch (err) {
        console.log("Oops, unable to copy");
      }
      document.body.removeChild(input);
    },
    downloadAIResultsJson() {
      let imei = this.imageMetadata["imei"];
      let text = this.imageMetadata;
      let filename = `ai_results_${imei}.json`;
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  background-color: rgb(155, 46, 46);
  color: white;
}
.btn {
  text-transform: unset !important;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #f4f6f8;
}
.w-100 {
  width: 80% !important;
  height: 40% !important;
}
textarea {
  overflow-y: scroll;
  min-height: 500px;
  width: 100%;
  resize: none; /* Remove this if you want the user to resize the textarea */
  color: white;
  background-color: rgb(155, 46, 46);
  margin-bottom: 10px;
}
</style>
