<template lang="">
  <div id="carouselExampleControls" class="carousel slide">
    <div class="carousel-inner">
      <CarouselItem
        v-for="image in cameraList"
        :link="image.link"
        :imageName="image.imageName"
        :key="image.id"
        :active="image.isActive"
        :directionClass="directionClass"
      ></CarouselItem>
    </div>
    <CarouselControl @goPrev="prev" bus="bus" order="prev"
      >Previous</CarouselControl
    >
    <CarouselControl @goNext="next" bus="bus" order="next"
      >Next</CarouselControl
    >
  </div>
</template>
<script>
import CarouselItem from "@/components/CarouselItem.vue";
import CarouselControl from "@/components/CarouselControl.vue";
import Vue from "vue";
export default {
  name: "CarouselComponent",
  components: {
    CarouselItem,
    CarouselControl,
  },
  props: {
    cameraList: Array,
  },
  data: function () {
    return {
      directionClass: "",
    };
  },
  methods: {
    getActiveIndex() {
      return this.cameraList.findIndex((obj) => obj.isActive);
    },
    getActiveCamera() {
      console.log(JSON.stringify(this.cameraList));
      this.cameraList.filter((obj) => {
        if (obj.isActive) {
          return obj;
        }
        //return obj;
      });
    },
    next() {
      if (this.cameraList.length > 1) {
        let activeIndex = this.getActiveIndex();

        let nextIndex = activeIndex + 1;
        let activeItem;
        let nextItem;

        if (nextIndex > this.cameraList.length - 1) {
          nextIndex = 0;
        }
        activeItem = this.cameraList[activeIndex];

        nextItem = this.cameraList[nextIndex];
        this.$emit("changeActiveCamera", nextItem);
        nextItem.isActive = true;
        activeItem.isActive = false;
        this.directionClass = "slide-next";
      }
    },
    prev() {
      if (this.cameraList.length > 1) {
        let activeIndex = this.getActiveIndex();
        let prevIndex = activeIndex - 1;
        let activeItem;
        let prevItem;

        if (prevIndex < 0) {
          prevIndex = this.cameraList.length - 1;
        }
        activeItem = this.cameraList[activeIndex];

        prevItem = this.cameraList[prevIndex];
        this.$emit("changeActiveCamera", prevItem);
        prevItem.isActive = true;
        activeItem.isActive = false;
        this.directionClass = "slide-prev";
      }
    },
  },
  created() {
    bus.$on("goPrev", this.prev).$on("goNext", this.next);
  },
  mounted() {
    let activeIndex = this.getActiveIndex();
    let activeItem = this.cameraList[activeIndex];
    this.$emit("changeActiveCamera", activeItem);
  },
};
const bus = new Vue();
</script>
<style lang=""></style>
