<template>
  <SmartphoneComponent></SmartphoneComponent>
</template>

<script>
// @ is an alias to /src
import SmartphoneComponent from '@/components/SmartphoneComponent.vue'

export default {
  name: 'SmartphoneView',
  components: {
    SmartphoneComponent
  }
}
</script>