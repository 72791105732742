<template>
  <div id="SmartphoneComponent">
    <div
      class="container-fluid"
      style="padding-top: 20px; padding-bottom: 20px"
    >
      <main>
        <div class="row" style="padding-top: 10px; padding-bottom: 10px">
          <div class="col-sm-3 col-12" style="padding-top: 10px; padding-bottom: 10px">
           
              <div class="card" style="min-height: 1150px;" >
                <div class="card-header" style="text-align: center">
                  Customer phone UI
                </div>
                <div class="card-body">
                  <!--start-->

                  <h6 style="text-align: center">
                    Aufnahmezeiten / picture recording times
                  </h6>
                  <div>
         

                  </div>
                  <form @submit.prevent="timerFeatureForm" style="padding: 5%">
                    <hr />
                           <div
                            class="form-group row"
                          >
                            <label class="col-sm-6 col-xs-12"
                              >Day</label
                            >
                            <div class="col-sm-6 col-xs-12">
                             
                              <input
                                type="text"
                                 class="form-control"
                                placeholder="minutes"
                                v-model="timerFeatureSettings.day"
                              />
                            </div>
                          </div>


                    <div v-for="element in timerFeatureSettings.timeframes" :key="element.id">
               <hr />
                          <div class="form-group row">
                            <label class="col-sm-6 col-xs-12"
                              >Timeframe {{ element.id }}</label
                            >
                            <div class="col-sm-6 col-xs-12">
                              <div 
                                class="custom-control custom-switch radio-btn"
                              >
                                <input  
                                  type="checkbox"
                                  class="custom-control-input" style=" background-color: rgb(155, 46, 46);"
                                  :id="element.id"
                                  :value="element.timeframe"
                                  v-model="element.timeframe"
                                  v-on:click="
                                    element.timeframe = !element.timeframe
                                  "
                                />

                                <label 
                                  class="custom-control-label"
                                  :for="element.id"
                                >
                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group row" v-if="element.timeframe">
                            <label class="col-sm-6"
                              >start time</label
                            >
                            <div class="col-sm-6 col-xs-12" >
                              <vue-timepicker input-width="100%"
                                v-model="element.startTime"
                             
                              ></vue-timepicker>
                            </div>
                          </div>

                          <div class="form-group row" v-if="element.timeframe">
                            <label class="col-sm-6 col-xs-12"
                              >end time</label
                            >
                            <div class="col-sm-6 col-xs-12">
                              <vue-timepicker input-width="100%"
                                v-model="element.stopTime"
                               
                              ></vue-timepicker>
                            </div>
                          </div>
                   
                 
                          <div class="form-group row"  v-if="element.timeframe">
                            <label class="col-sm-6 col-xs-12"  >Time lapse</label>
                            <div class="col-sm-6 col-xs-12">
                              <div
                                class="custom-control custom-switch timeinterval radio-btn"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="'element.timelapse' + element.id"
                                  :value="element.timelapse"
                                  v-model="element.timelapse"
                                  v-on:click="
                                    element.timelapse = !element.timelapse
                                  "
                                />

                                <label
                                  class="custom-control-label"
                                  :for="'element.timelapse' + element.id"
                                >
                                </label>
                              </div>
                            </div>
                          </div>

                          <div
                            class="form-group row"
                            v-if="element.timelapse && element.timeframe"
                          >
                            <label class="col-sm-6 col-xs-12"
                              >Recording interval (mins)</label
                            >
                            <div class="col-sm-6 col-xs-12">
                              <input
                                type="number"
                                class="form-control"
                                id="exampleFormControlInput1"
                                min="0"
                                max="59"
                                placeholder="minutes"
                               v-model="element.timelapseIntervalMins"
                              />
                            </div>
                          </div>

                
                    </div>

                    <!--loop end-->


                      <div class="form-group row">
                            <label class="col-sm-6 col-xs-12"
                              >Control photo</label
                            >
                            <div class="col-sm-6 col-xs-12">
                              <div 
                                class="custom-control custom-switch radio-btn"
                              >
                                <input  
                                  type="checkbox"
                                  class="custom-control-input" style=" background-color: rgb(155, 46, 46);"
                                  id="cs1"
                                  :value="timerFeatureSettings.controlPicture.status"
                                  v-model="timerFeatureSettings.controlPicture.status"
                                  v-on:click="
                                    timerFeatureSettings.controlPicture.status = !timerFeatureSettings.controlPicture.status
                                  "
                                />

                                <label 
                                  class="custom-control-label"
                                  for="cs1"
                                >
                                </label>
                              </div>
                            </div>
                          </div>
                            <div v-if="timerFeatureSettings.controlPicture.status">
                            <div class="form-group row"  v-for="element in timerFeatureSettings.controlPicture.values" :key="element.id">
                            <label class="col-sm-6"
                              >{{element.name}} control photo</label
                            >
                            <div class="col-sm-6 col-xs-12" >
                              <vue-timepicker input-width="100%"
                                v-model="element.time"
                              ></vue-timepicker>
                            </div>
                          </div>
                            </div>

                  </form>
                  <!--end-->
                  <hr />

                  <button
                    type="submit"
                    value="Submit"
                    id="btn-one"
                    class="btn button wrapperbtn"
                    @click="timerFeatureForm"
                  >
                    Apply settings
                  </button>
                  <hr />
                  <div v-if="acknowledgeAPIRes != ''">
                    <p>Response for customer UI</p>
                    <p
                      style="
                        background-color: rgb(155, 46, 46);
                        color: white;
                        padding: 5px;
                      "
                    >
                      {{ acknowledgeAPIRes }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
    

          <div class="col-md-9 col-12" style="padding-top: 10px; padding-bottom: 10px">
            
              <div class="card" style="min-height: 1150px;">
                <div class="card-header" style="text-align: center">
                  Camera APIs and workflow
                </div>
                <div class="card-body">
                  <p>
                    When the customer will the apply settings button then the
                    backend will received the request. The backend will create a
                    jobID for this command and request the camera to apply these
                    settings in the firmware. The backend will send the settings
                    paramaters as body in JSON format to the Camera POST API.
                    The Camera API should be able to receive this JSON from the request body
                    and then apply the settings in the firmware. The Camera is
                    suppose to acknowledge the backend after the completion of each job via our backend acknowledge API with
                    jobID and status 'completed' after successfully applying the settings in the firmware.
                  </p>

                  <p>
                    For simulation purpose, we use the following API to
                    demonstrate camera behaviour:
                  </p>

                  <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-2 col-form-label"
                      >Customer Ident Code</label
                    >
                    <div class="col-sm-2">
                      <input
                        class="form-control"
                        type="text"
                        v-model="customerCamerasIdent"
                        placeholder="customer ident code"
                        readonly
                      />
                    </div>
                  </div>

                  <hr />
                  <h5>POST</h5>
                  <p>
                    {{ applyTimerSettingsURL }}
                  </p>

                  <hr />
                  <p>Request body</p>
                  <div class="pre-scrollable">
                    <pre>{{ timerFeatureSettings }}</pre>
                  </div>
                 
                  <hr />
                  <!-- <p>API response</p>
                  <pre
                    style="background-color: rgb(155, 46, 46); color: white"
                  ></pre>
                  <hr /> -->
                </div>
              </div>
         
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  name: "SmartphoneComponent",
  components: {
    VueTimepicker,
  },
  props: {
    msg: String,
  },
  data: function () {
    return {
      myvar:69,
      sufix:'',
      runningEnv: 'local',
      applyTimerSettingsURL: String,
      acknowledgeAPIRes: "",
      customerCamerasIdent: 868276106892753,
      TimeFrameObject: {
        id: Number,
        timeframe: Boolean,
        startTime: String,
        stopTime: String,
        timelapse: Boolean,
        timelapseIntervalMins: Number,
      },
       ControlPictureObject: [
                {
                    id: Number,
                    name:String,
                    time: String
                }
            ]
     ,
      timerFeatureSettings:{
          day: String,
           timeframes: Array < this.TimeFrameObject >=[],
           controlPicture: {
        status:Boolean,
        values:this.ControlPictureObject
        }
      }
    };
  },
 computed: {
    checkedName: {
      // getter
      get: function () {
        return `${this.myvar}${this.sufix}`;
      },
      // setter
      set: function (newValue) {
        this.myvar = newValue.replace(this.sufix,'');
      }
    }
  },
  created() {
    console.log("çreated");
    // this.loadAppConfigs();
    this.getEnv();
    
     this.timerFeatureSettings ={
          day : 'monday',
           timeframes : [
      {
        id: 1,
        timeframe: true,
        startTime: "02:30",
        stopTime: "18:00",
        timelapse: true,
        timelapseIntervalMins: 15,
      },
       {
        id: 2,
        timeframe: true,
        startTime: "02:30",
        stopTime: "18:00",
        timelapse: true,
        timelapseIntervalMins: 20,
      }
    ] ,
   controlPicture: {
    status:true,
    values: [
      {
        id:1,
        name: 'first',
        time: '07:00'
      },
            {
        id:2,
        name: 'second',
        time: '08:00'
      },
            {
        id:3,
        name: 'third',
        time: '21:00'
      }
    ]
  }
      }
      
    
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  methods: {
    async getEnv() {
      await axios
        .get("api/getAppEnv")
        .then((res) => {
          this.runningEnv = res.data.env;
          console.log(this.runningEnv);
          if (this.runningEnv === "dev") {
            this.applyTimerSettingsURL = `https://api-dev.vtcamera.de/api/camerasimulator/applytimersettings/${this.customerCamerasIdent}`;
          }
          if (this.runningEnv != "dev") {
            this.applyTimerSettingsURL = `http://localhost:8080/api/camerasimulator/applytimersettings/${this.customerCamerasIdent}`;
          }
          console.log("Res data " + JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    timerFeatureForm: function () {
      
      this.updateTimerSettingBackendAPI();
    },
    async updateTimerSettingBackendAPI() {
      console.log('Send request to backend with body');
      console.log(JSON.stringify(this.timerFeatureSettings));
      await axios
        .post(
          `api/timersettings/update/${this.customerCamerasIdent}`,
          this.timerFeatureSettings
        )
        .then((res) => {
          console.log(res.data);
          this.acknowledgeAPIRes = res.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    // inputHandler(eventData) {
    //   console.log(eventData);
    // }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  background-color: rgb(155, 46, 46);
  color: white;
}
.btn {
  text-transform: unset !important;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #f4f6f8;
}
.card-img-top {
  width: 400px;
  height: 15vw;
  object-fit: contain;
}
.radio-btn {
  /* position:absolute; */
  position: absolute;
  right: 1vh;
  
}
.custom-control-label:before{
  background-color: grey !important;
  color: white !important;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before{
  background-color:rgb(155, 46, 46) !important;
   color: white !important;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after{
  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !important;
}

.wrapperbtn {
  margin: auto;
  display: block;
}

.pre-scrollable {
    height:auto !important;
    max-height: 680px !important;
    overflow-y: scroll !important;
    background-color: rgb(155, 46, 46); 

}
.pre-scrollable>pre {
   color: white !important;
}


</style>
