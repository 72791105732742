<template>
  <!-- Footer -->
  <footer class="footer font-small pt-8">
    <!-- Footer Links -->
    <div class="container-fluid text-left text-md-left">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12 col-12 mt-md-0 mt-3">
          <!-- Content -->
  
          <!-- <p>Major release (Auto app versioning)</p>
                        <p>Last version releases (UI updates, multiple alarm devices setup.)</p> -->
          <br />
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
    <!-- Footer Links -->

    <!-- Copyright -->
    <div class="footer-copyright text-center py-1">
      © 2022 VenTrade GmbH:
      <a href="https://www.ventrade.de/"> ventrade.de</a>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
</template>

<script>
// @ is an alias to /src
export default {
  name: "FooterComponent",
  components: {},
  data : function() {
      return {
           appVersion: "",
      }
  },
  created() {
  },
  methods: {

  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #f4f6f8;
}
</style>
